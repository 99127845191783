<template>
  <div >
    <page-header/>
    <section class="uk-section uk-section-small uk-padding-remove">
      <div class="Home uk-container">

        <div class="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid>
          <div class="uk-text-center">
            <ul class="uk-breadcrumb uk-flex-center uk-margin-remove">
              <li v-for="(item, index) in sitemaps" :key="index" v-html="item"></li>
            </ul>
            <h1 class="uk-margin-small-top uk-margin-remove">{{title}}</h1>
            <div class="uk-text-meta uk-margin-xsmall-top">
                  <span v-if="vtype === 'Question'">
                    <span v-if="questions!==null">{{questions.length}}</span>
                    <span v-else>0</span>
                  </span>
                  <span v-else>
                    <span v-if="answers!==null">{{answers.length}}</span>
                    <span v-else>0</span>
                  </span>
            </div>
          </div>

          <div class="uk-grid-margin">
            <div class="uk-grid-medium uk-grid uk-grid-stack" uk-grid>
              <aside
                class="uk-width-1-4 tm-aside-column tm-filters uk-grid-match"
                id="filters" uk-offcanvas="overlay: true; container: false;">
                <div class="uk-offcanvas-bar uk-padding-remove">
                  <div class="uk-card uk-card-default uk-card-small uk-flex uk-flex-column uk-height-1-1">
                    <header class="uk-card-header uk-flex uk-flex-middle">
                      <div class="uk-grid-small uk-flex-1" uk-grid>
                        <div class="uk-width-expand">
                          <h3>Filters</h3>
                        </div>
                        <button type="button" class="uk-offcanvas-close"  uk-close></button>
                      </div>
                    </header>
                    <div
                      class="uk-margin-remove uk-flex-1 uk-overflow-auto"
                      uk-accordion="multiple: true; targets: &gt; .js-accordion-section"
                      style="flex-basis: auto"
                    >
                      <section class="uk-card-body js-accordion-section uk-open">
                        <h4 class="uk-accordion-title">
                          {{$t("organization.dataMarketList.cate")}}</h4>
                        <div class="uk-MyHistory.vueaccordion-content" aria-hidden="false">
                          <ul class="uk-list tm-scrollbox" v-if="Array.isArray(maincates)">
                            <app-category-item
                              v-for="item in maincates"
                              :item="item"
                              :key="item.id"
                              v-on:update-category="handleCategory"
                            ></app-category-item>
                          </ul>
                        </div>
                      </section>
                      <section class="uk-card-body js-accordion-section uk-open">
                        <h4 class="uk-accordion-title">
                          {{$t("organization.dataMarketList.cate")}}
                          <span
                            class="tm-help-icon"
                            uk-icon="icon: question; ratio: .75;"
                            onclick="event.stopPropagation();"
                          ></span>
                          <div
                            class="uk-margin-remove"
                            uk-drop="mode: click;boundary-align: true; boundary: !.uk-accordion-title; pos: bottom-justify;"
                          >
                            <div
                              class="uk-card uk-card-body uk-card-default uk-card-small uk-box-shadow-xlarge uk-text-small"
                            >asuult bolon data gaar filter hiih
                            </div>
                          </div>
                        </h4>
                        <div class="uk-accordion-content">
                          <ul class="uk-list tm-scrollbox">
                            <li>
                              <input
                                class="tm-radio"
                                id="type-1"
                                name="type"
                                v-on:change="changetype"
                                value="Question"
                                type="radio"
                                v-model="vtype"
                              />
                              <label for="type-1">
                                    <span>
                                      {{$t("organization.dataMarketList.ques")}}
                                      <span class="uk-text-meta uk-text-xsmall">150</span>
                                    </span>
                              </label>
                            </li>
                            <li>
                              <input
                                class="tm-radio"
                                id="type-2"
                                name="type"
                                v-on:change="changetype"
                                v-model="vtype"
                                value="Data"
                                type="radio"
                              />
                              <label for="type-2">
                                    <span>
                                      {{$t("organization.dataMarketList.data")}}
                                      <span class="uk-text-meta uk-text-xsmall">18</span>
                                    </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </section>

                      <section class="uk-card-body js-accordion-section uk-open">
                        <h4 class="uk-accordion-title">
                          {{$t("organization.dataMarketList.mType")}}
                          <span
                            class="tm-help-icon"
                            uk-icon="icon: question; ratio: .75;"
                            onclick="event.stopPropagation();"
                          ></span>
                          <div
                            class="uk-margin-remove"
                            uk-drop="mode: click;boundary-align: true; boundary: !.uk-accordion-title; pos: bottom-justify;"
                          >
                            <div
                              class="uk-card uk-card-body uk-card-default uk-card-small uk-box-shadow-xlarge uk-text-small"
                            ></div>
                          </div>
                        </h4>
                        <div class="uk-accordion-content">
                          <ul class="uk-list tm-scrollbox">
                            <li>
                              <input
                                class="tm-checkbox"
                                name="type"
                                id="cbvideo"
                                v-on:change="mediachange"
                                value="2"
                                type="checkbox"
                                v-model="mediatype"
                              />
                              <label for="cbvideo">
                                    <span>
                                      {{$t("organization.dataMarketList.video")}}
                                      <span class="uk-text-meta uk-text-xsmall">150</span>
                                    </span>
                              </label>
                            </li>
                            <li>
                              <input
                                class="tm-checkbox"
                                name="type"
                                id="cbimage"
                                v-on:change="mediachange"
                                value="1"
                                type="checkbox"
                                v-model="mediatype"
                              />
                              <label for="cbimage">
                                    <span>
                                      {{$t("organization.dataMarketList.img")}}
                                      <span class="uk-text-meta uk-text-xsmall">150</span>
                                    </span>
                              </label>
                            </li>
                            <li>
                              <input
                                class="tm-checkbox"
                                name="type"
                                id="cbstack"
                                v-on:change="mediachange"
                                value="4"
                                type="checkbox"
                                v-model="mediatype"
                              />
                              <label for="cbstack">
                                    <span>
                                      {{$t("organization.dataMarketList.stack")}}
                                      <span class="uk-text-meta uk-text-xsmall">150</span>
                                    </span>
                              </label>
                            </li>
                            <li>
                              <input
                                class="tm-checkbox"
                                name="type"
                                id="cbaudio"
                                v-on:change="mediachange"
                                value="3"
                                type="checkbox"
                                v-model="mediatype"
                              />
                              <label for="cbaudio">
                                    <span>
                                      {{$t("organization.dataMarketList.audio")}}
                                      <span class="uk-text-meta uk-text-xsmall">150</span>
                                    </span>
                              </label>
                            </li>
                          </ul>
                        </div>
                      </section>

                      <section class="uk-card-body uk-open js-accordion-section">
                        <h4 class="uk-accordion-title">{{$t("organization.dataMarketList.point")}}</h4>
                        <div class="uk-accordion-content">
                          <div class="uk-text-left">
                            <ul class="uk-list tm-scrollbox">
                              <li>
                                <input
                                  class="tm-checkbox"
                                  name="type"
                                  id="cbfree"
                                  ref="rolefree"
                                  @change="checkfree($event)"
                                  type="checkbox"
                                  :v-model="isfree"
                                />
                                <label for="cbfree">
                                  <span>{{$t("organization.dataMarketList.free")}}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                          <div
                            v-if="isfree == 'false'"
                            class="uk-grid-small uk-child-width-1-2 uk-text-small"
                            uk-grid
                          >
                            <div>
                              <div class="uk-inline">
                                    <span class="uk-form-icon uk-text-xsmall">
                                      {{$t("organization.dataMarketList.from")}}
                                    </span>
                                <input
                                  v-model="firstpoint"
                                  class="uk-input"
                                  type="text"
                                  placeholder="59"
                                />
                              </div>
                            </div>
                            <div>
                              <div class="uk-inline">
                                    <span class="uk-form-icon uk-text-xsmall">
                                      {{$t("organization.dataMarketList.to")}}
                                    </span>
                                <input
                                  v-model="lastpoint"
                                  class="uk-input"
                                  type="text"
                                  placeholder="6559"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                      <div class="uk-card-body uk-align-center uk-text-center">
                        <button
                          class="uk-button uk-button-default uk-width-1-1"
                          @click="resetfilter" style="width: 213px;">
                          <span class="uk-margin-xsmall-right" uk-icon="icon: close; ratio: .75;"></span>
                          {{$t("organization.dataMarketList.filters")}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>

              <div class="uk-width-expand">
                <div class="uk-grid-medium uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid>
                  <div class="uk-first-column">
                    <div class="uk-card uk-card-default uk-card-small tm-ignore-container">
                      <div class="uk-grid-collapse uk-child-width-1-1 uk-grid uk-grid-stack"
                        id="products"
                        uk-grid >
                        <div class="uk-card-header">
                          <div class="uk-grid-small uk-flex-middle" uk-grid>
                            <div
                              class="uk-width-1-1 uk-width-expand@s uk-flex uk-flex-center uk-flex-left@s uk-text-small"
                            >
                                  <span
                                    style="margin-right:10px"
                                    class="uk-flex uk-flex-middle"
                                  >{{$t("organization.dataMarketList.sortBy")}}</span>

                              <div uk-form-custom="target: > * > span:first-child">
                                <select v-model="sortselected" @change="onChangeSort($event)" class="uk-input">
                                  <option value="norm">{{$t("organization.dataMarketList.normal")}}</option>
                                  <option value="new">{{$t("organization.dataMarketList.nData")}}</option>
                                  <option value="earliest">{{$t("organization.dataMarketList.oData")}}</option>
                                  <option value="cheap">{{$t("organization.dataMarketList.pUp")}}</option>
                                  <option value="expensive">{{$t("organization.dataMarketList.pDown")}}</option>
                                  <option value="sale">{{$t("organization.dataMarketList.sale")}}</option>
                                </select>
                                <button
                                  class="uk-button uk-button-default"

                                  tabindex="-1"
                                >

                                  <span uk-icon="icon: chevron-down"></span>
                                </button>
                              </div>
                            </div>
                            <div
                              class="uk-width-1-1 uk-width-auto@s uk-flex uk-flex-center uk-flex-middle"
                            >
                              <button
                                class="uk-button uk-button-default uk-button-small uk-hidden@m"
                                uk-toggle="target: #filters"
                              >
                                    <span
                                      class="uk-margin-xsmall-right"
                                      uk-icon="icon: settings; ratio: .75;"
                                    ></span>
                                {{$t("organization.dataMarketList.filters")}}
                              </button>
                              <div class="tm-change-view uk-margin-small-left">
                                <ul class="uk-subnav uk-iconnav js-change-view" uk-switcher>
                                  <li>
                                    <a
                                      class="uk-active"
                                      data-view="grid"
                                      uk-icon="grid"
                                      uk-tooltip="Grid"
                                      @click.prevent="changeDisplay(false)"
                                    ></a>
                                  </li>
                                  <li>
                                    <a
                                      data-view="list"
                                      uk-icon="list"
                                      uk-tooltip="List"
                                      @click.prevent="changeDisplay(true)"
                                    ></a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="uk-grid-margin">
                          <div
                            v-if="answers!=null && answers.length > 0"
                            class="uk-grid-collapse uk-child-width-1-3 js-products-grid uk-grid tm-products-grid"
                            :class="displayList"
                          >
                            <app-answer-item v-for="ans in answers" :item="ans" :key="ans.id"></app-answer-item>
                          </div>

                          <div
                            v-else-if="questions!=null && questions.length > 0"
                            class="uk-grid-collapse uk-child-width-1-3 js-products-grid uk-grid tm-products-list">
                            <!-- :class="displayList" -->

                            <app-question-item
                              v-for="question in questions"
                              :item="question"
                              :key="question.id"
                              :displayList="displayList"
                            ></app-question-item>
                          </div>
                          <div v-else>
                            <div v-if="isLoading==false" class="uk-alert-primary" uk-alert>
                              <a class="uk-alert-close" uk-close></a>
                              <p class="uk-text-center">{{$t("organization.dataMarketList.eDatas")}}</p>
                            </div>
                          </div>
                          <div class="uk-flex uk-flex-center">
                            <br>
                            <grid-loader
                              class="uk-margin"
                              :loading="isLoading"
                              :color="loaderColor"
                              :size="loaderSize"
                            ></grid-loader>
                          </div>
                        </div>

                        <div class="uk-grid-margin">
                          <button
                            class="uk-button uk-button-default uk-button-large uk-width-1-1"
                            style="border-top-left-radius: 0; border-top-right-radius: 0;"
                            @click.prevent="loadMore"
                            v-if="isLoadMoreBtn"
                          >
                                <span
                                  class="uk-margin-small-right uk-icon"
                                  uk-icon="icon: plus; ratio: .75;"
                                ></span>
                            <span>{{$t("organization.dataMarketList.lMore")}}</span>
                          </button>

                        </div>

                        <div hidden class="uk-grid-margin">
                          <ul class="uk-pagination uk-flex-center">
                            <li class="uk-active">
                              <span>1</span>
                            </li>
                            <li>
                              <a href="#">2</a>
                            </li>
                            <li class="uk-disabled">
                              <span>…</span>
                            </li>
                            <li>
                              <a href="#">20</a>
                            </li>
                            <li>
                              <a href="#">
                                <span uk-pagination-next class="uk-pagination-next uk-icon"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <page-footer/>
  </div>

</template>

<script scoped>
  import {mapGetters} from 'vuex'
  import AnswersService from '@/services/AnswersService'
  import CategoryService from '@/services/CategoryService'
  import AnswerItem from '@/components/globitemviews/Answer/AnswerItemm'
  import QuestionItem from '@/components/globitemviews/Answer/QuestionItem'
  import CategoryItem from '@/components/globitemviews/Category/CategoryItem'
  import PageHeader from '@/components/Organizcmp/OrganizHeader.vue'
  const PageFooter = () => import("../Organizcmp/OrganizFooter.vue")

  import GridLoader from 'vue-spinner/src/GridLoader.vue'
  import _ from 'lodash'

  export default {
    name: 'Home',
    components: {
      appAnswerItem: AnswerItem,
      appQuestionItem: QuestionItem,
      appCategoryItem: CategoryItem,
      GridLoader,
      PageHeader,
      PageFooter
    },
    data() {
      return {
        answers: null,
        questions: null,
        displayList: 'tm-products-grid',
        currentPage: 0,
        pages: 0,
        isLoadMoreBtn: true,
        isLoadMoreFinish: false,
        isLoading: false,
        loaderColor: 'rgb(1, 176, 255)',
        loaderSize: '25px',
        vtype: 'Data',
        mediatype: [],
        sortselected: 'norm',
        firstpoint: '',
        lastpoint: '',
        maincates: null,
        reqparams: {},
        sitemaps: [],
        title: '',
        isfree: 'false'
      }
    },
    watch: {
      $route() {
        if (this.$route.query.q) {
          this.initdata()
        }
      },
      // eslint-disable-next-line no-unused-vars
      lastpoint: _.debounce(async function (value) {
        if (this.lastpoint !== '' && this.firstpoint !== '') {
          this.changetype()
        }
      }, 700),
      // eslint-disable-next-line no-unused-vars
      firstpoint: _.debounce(async function (value) {
        if (this.lastpoint !== '' && this.firstpoint !== '') {
          this.changetype()
        }
      }, 700)
    },
    created() {
      // refresh hiihed urlparamaas hamarch default utguudaa oorchiloh
      if (this.$route.query.sort) {
        this.sortselected = this.$route.query.sort
      }
      if (this.$route.query.mtype0) {
        this.mediatype[0] = this.$route.query.mtype0
      }
      if (this.$route.query.mtype1) {
        this.mediatype[1] = this.$route.query.mtype1
      }
      if (this.$route.query.mtype3) {
        this.mediatype[3] = this.$route.query.mtype3
      }
      if (this.$route.query.mtype4) {
        this.mediatype[4] = this.$route.query.mtype4
      }
      if (this.$route.query.datatype) {
        if (this.$route.query.datatype === 'Question') {
          this.vtype = 'Question'
        } else {
          this.vtype = 'Data'
        }
      }

      // end

      this.initdata()
    },
    async mounted() {
      this.loadMainCates()

      window.addEventListener('scroll', () => {
        this.bottom(this.bottomVisible())
        // console.log(this.bottomVisible())
      })
      // console.log(this.player)
    },
    methods: {
      checkfree(e) {
        console.log(this.isfree)
        if (e.srcElement.checked) {
          // alert('hi')
          this.isfree = 'true'
        } else {
          this.isfree = 'false'
        }
        this.changetype()
      },
      resetfilter() {
        //  this.reqparams = {}

        this.answers = null
        this.questions = null
        this.currentPage = 0
        this.pages = 0
        this.isLoadMoreBtn = true
        this.isLoadMoreFinish = false
        this.isLoading = false

        // this.vtype = 'Data'
        this.mediatype = []
        this.sortselected = 'norm'
        this.firstpoint = ''
        this.lastpoint = ''
        this.reqparams = {}
        this.sitemaps = []
        this.title = 'Products'
        this.isfree = 'false'
        this.$refs.rolefree.checked = false

        this.setreqparam()
        this.reqparams['datatime'] = new Date().getTime()
        this.$router.push({name: 'datamarket', query: this.reqparams})
        this.changetype()
      },

      initdata() {
        this.title = this.vtype
        this.isLoading = true

        this.answers = null
        this.questions = null
        this.currentPage = 0
        this.pages = 0
        this.isLoadMoreBtn = true
        this.isLoadMoreFinish = true
        this.isLoading = true

        this.setreqparam()
        if (this.$route.query.q) {
          this.reqparams['q'] = this.$route.query.q
        }
        if (this.$route.query.keyword) {
          this.reqparams['keyword'] = this.$route.query.keyword
        }

        const page = this.currentPage
        this.reqparams['page'] = page
        this.$router.push({query: this.reqparams})
        if (this.vtype === 'Data') {
          AnswersService.getAnswersByPage(this.reqparams).then(res => {
            const {data} = res
            this.answers = data.content
            if (!this.answers.length) {
              this.isLoadMoreBtn = false
              this.isLoadMoreFinish = true
              this.isLoading = false
              return
            }
            this.pages = res.totalPages
            this.currentPage = page
            this.isLoading = false
          })
        } else {
          AnswersService.getQuestionsByPage(this.reqparams).then(res => {
            const {data} = res
            console.log(data)
            this.questions = data.content
            if (!this.questions.length) {
              this.isLoadMoreBtn = false
              this.isLoadMoreFinish = true
              this.isLoading = false
              return
            }

            this.pages = res.totalPages
            this.currentPage = page
            this.isLoading = false
          })
        }
      },
      // eslint-disable-next-line no-unused-vars
      onChangeSort(event) {
        this.initdata()
      },
      // eslint-disable-next-line no-unused-vars
      handleCategory(item) {
        this.changetype()
        // console.log(item.name)
      },
      setreqparam() {
        var i
        this.reqparams = {}
        for (i = 0; i < this.mediatype.length; i++) {
          this.reqparams['mtype' + i] = this.mediatype[i]
        }

        if (this.sortselected) {
          this.reqparams['sort'] = this.sortselected
        }

        if (this.lastpoint) {
          this.reqparams['lpoint'] = this.lastpoint
        }

        if (this.firstpoint) {
          this.reqparams['fpoint'] = this.firstpoint
        }
        if (this.isfree) {
          this.reqparams['isfree'] = this.isfree
        }
        if (this.vtype) {
          this.reqparams['datatype'] = this.vtype
        }

        this.sitemaps = []

        if (this.$route.params.catid) {
          this.reqparams['catid'] = this.$route.params.catid

          this.sitemaps[0] = `<a href="/organization/datamarket/t/${
            this.$route.params.slug
          }-${this.$route.params.catid}">${this.$route.params.slug}</a>`
          this.title = this.$route.params.slug
        }
        if (this.$route.params.catid2) {
          this.reqparams['catid2'] = this.$route.params.catid2
          this.sitemaps[1] = `<a href="/organization/datamarket/t/${
            this.$route.params.slug
          }-${this.$route.params.catid}/${this.$route.params.slug2}-${
            this.$route.params.catid2
          }">${this.$route.params.slug2}</a>`
          this.title = this.$route.params.slug2
        } else {
          this.sitemaps = []
        }
        if (this.$route.params.catid3) {
          this.reqparams['catid3'] = this.$route.params.catid3
          this.sitemaps[2] = `<a href="/organization/datamarket/t/${
            this.$route.params.slug
          }-${this.$route.params.catid}/${this.$route.params.slug2}-${
            this.$route.params.catid3
          }/${this.$route.params.slug3}-${this.$route.params.catid2}">${
            this.$route.params.slug3
          }</a>`
          this.title = this.$route.params.slug3
        }
      },
      mediachange() {
        //
        console.log('mediachange')
        this.changetype()
      },
      changetype() {
        // alertalert(this.vtype)
        this.title = this.vtype
        this.answers = null
        this.questions = null
        this.currentPage = 0
        this.pages = 0
        this.isLoadMoreBtn = true
        this.isLoadMoreFinish = true
        this.isLoading = true

        this.setreqparam()

        const page = this.currentPage
        this.reqparams['page'] = page
        this.$router.push({query: this.reqparams})
        if (this.vtype === 'Data') {
          AnswersService.getAnswersByPage(this.reqparams).then(res => {
            const {data} = res
            this.answers = data.content
            if (!this.answers.length) {
              this.isLoadMoreBtn = false
              this.isLoadMoreFinish = true
              this.isLoading = false
              return
            }
            this.pages = res.totalPages
            this.currentPage = page
            this.isLoading = false
          })
        } else {
          AnswersService.getQuestionsByPage(this.reqparams).then(res => {
            const {data} = res
            console.log(data)
            this.questions = data.content
            if (!this.questions.length) {
              this.isLoadMoreBtn = false
              this.isLoadMoreFinish = true
              this.isLoading = false
              return
            }

            this.pages = res.totalPages
            this.currentPage = page
            this.isLoading = false
          })
        }
      },
      changeDisplay(isList) {
        if (isList) {
          this.displayList = 'tm-products-list'
        } else {
          this.displayList = 'tm-products-grid'
        }
      },
      bottomVisible() {
        const scrollY = window.scrollY
        const visible = document.documentElement.clientHeight
        const pageHeight = document.documentElement.scrollHeight
        const bottomOfPage = visible + scrollY >= pageHeight
        return bottomOfPage || pageHeight < visible
      },

      loadMore() {
        if (this.vtype === 'Data') {
          this.loadMoreAnswers()
        } else {
          this.loadMoreQuestions()
        }
      },
      loadMoreAnswers() {
        this.isLoading = true

        const page = this.currentPage + 1
        this.reqparams['page'] = page
        console.log(this.reqparams)
        AnswersService.getAnswersByPage(this.reqparams).then(res => {
          const {data} = res

          if (!data.content.length) {
            this.isLoadMoreBtn = false
            this.isLoadMoreFinish = true
            this.isLoading = false
            return
          }

          data.content.forEach(answer => {
            this.answers.push(answer)
          })
          this.pages = res.totalPages
          this.currentPage = page
          this.isLoading = false
        })
      },
      loadMoreQuestions() {
        this.isLoading = true

        const page = this.currentPage + 1
        this.reqparams['page'] = page
        AnswersService.getQuestionsByPage(this.reqparams).then(res => {
          const {data} = res

          console.log(data)
          if (!data.content.length) {
            this.isLoadMoreBtn = false
            this.isLoadMoreFinish = true
            this.isLoading = false
            return
          }

          data.content.forEach(question => {
            this.questions.push(question)
          })
          this.pages = res.totalPages
          this.currentPage = page
          this.isLoading = false
        })
      },
      loadMainCates() {
        CategoryService.getMainCategories().then(res => {
          const {data} = res
          // console.log(data)
          this.maincates = data
        })
      },
      // eslint-disable-next-line no-unused-vars
      bottom(bottom) {
        // console.log(`orlooooo ${bottom} ${this.isLoadMoreFinish}`)
        // if (bottom && !this.isLoadMoreFinish) {
        //   if (this.vtype === 'Data') {
        //     if (!this.isLoadMoreFinish) {
        //       this.loadMoreAnswers()
        //     }
        //   } else {
        //     if (!this.isLoadMoreFinish) {
        //       this.loadMoreQuestions()
        //     }
        //   }
        // }
      }
    },
    computed: {
      ...mapGetters(['isProductLoading'])
    }
  }
</script>

<style scoped>
  .uk-list>li>:last-child {
    margin: 10px;
  }
  .uk-accordion-title {
    padding-left: 10px;
    padding-bottom: 10px;
  }
  .uk-input{
    border-radius: 10px;
    text-align: center;
  }
  .uk-card{
    border-radius: 15px;
  }
</style>
