<template>
  <li :id="item.id">
    <router-link
      tag="a"
      @click.native="clickCategory(item)"
      :to="{ name: 'category',params: { slug: slugify(item.name),
                                        catid: item.id}}"
      :class="{
          'active': currentCategory == item.name
        }"
      class
    >{{item.name}}</router-link>
    <ul class="uk-list uk-list-small" v-if="subcates!=null&&subcates.parent_id == item.id">
      <li :id="sitem.id" v-for="(sitem, sindex) in subcates.cates" :key="sindex">
        <router-link
          tag="a"
          @click.native="clickCategory(sitem)"
          :to="{ name: 'categorys',params: {slug: slugify(item.name),
                                        catid: item.id, slug2: slugify(sitem.name),
                                        catid2: sitem.id}}"
          :class="{
          'active': currentCategory == sitem.name
        }"
          class
        >{{sitem.name}}</router-link>

        <!-- 3 sub cates -->
        <ul class="uk-list uk-list-small" v-if="subcates2!=null&&subcates2.parent_id == sitem.id">
          <li :id="s2item.id" v-for="(s2item, s2index) in subcates2.cates" :key="s2index">
            <router-link
              tag="a"
              @click.native="clickCategory(s2item)"
              :to="{ name: 'categoryss',params: {slug: slugify(item.name),
                                        catid: item.id, slug2: slugify(sitem.name),
                                        catid2: sitem.id, slug3: slugify(s2item.name),
                                        catid3: s2item.id}}"
              :class="{
          'active': currentCategory == sitem.name
        }"
              class
            >{{s2item.name}}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </li>
</template>

<script>
import CategoryService from '@/services/CategoryService'
export default {
  props: ['item'],
  components: {
  },
  data: function () {
    return {
      currentCategory: '',
      subcates: null,
      subcates2: null
    }
  },
  methods: {
    clickCategory: function (item) {
      this.$emit('update-category', item)
    },
    loadMainCates () {
      CategoryService.getMainCategories().then(res => {
        const { data } = res
        // console.log(data)
        this.maincates = data
      })
    },
    loadSubCates (val, type) {
      CategoryService.getSubCates(val).then(res => {
        const { data } = res
        console.log(data)
        if (type === 0) {
          this.subcates = data
        } else {
          this.subcates2 = data
        }
      })
    },
    getCurrentCategory () {
      if (this.$route.params.slug) {
        this.currentCategory = this.$route.params.slug
      }
    },
    slugify: function (value) {
      if (!value) return ''

      var slug = ''
      // Change to lower case
      var valueLower = value.toLowerCase()
      // Letter "e"
      slug = valueLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e')
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a')
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o')
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u')
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd')
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '_')

      return slug
    }
  },
  created () {
    this.getCurrentCategory()

    if (this.$route.params.catid) {
      this.loadSubCates(this.$route.params.catid, 0)
    }
    if (this.$route.params.catid2) {
      this.loadSubCates(this.$route.params.catid2, 1)
    }
  },
  watch: {
    $route () {
      this.getCurrentCategory()
    },
    '$route.params.slug': function () {
      if (this.$route.params.catid) {
        this.loadSubCates(this.$route.params.catid, 0)
      }
    },
    '$route.params.slug2': function () {
      if (this.$route.params.catid2) {
        this.loadSubCates(this.$route.params.catid2, 1)
      }
    }
  },
  filters: {},
  mounted () {}
}
</script>

<style scoped>
li {
  display: block;
}
li a {
  display: block;
  color: #666;
  font-size: 18px;
}

.active{
  color:red;
}

</style>

